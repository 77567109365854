<script lang="ts" setup>
  import type { DocumentResource } from "../../../../widgetDataTs.js";
  import { computed } from 'vue';
  import { useFormServices } from "../../../composables/use-form-services.js";
  import { useTranslation } from "i18next-vue";
  import { TranslationNamespaceEnum } from "../../../locale/settings/translation-namespaces.js";

  defineProps<{ 
    documents: DocumentResource[]
  }>();

  const { FormState } = useFormServices({ callerLabel: "documentForm" });
  const showUnlockedView = computed(() => {
    return FormState.value === "RegularFormComplete" || FormState.value === "BlindForm";
  });
  const { t } = useTranslation();
</script>

<template>
  <div class="eclipse-form-root mc-document-form neutral" :class="{success: showUnlockedView}" data-uniq-form-id="">
    <div class="eclipse-content-wrpr">
      <div class="left-col resource dark">
        <div class="resources-header">
          <p class="requested before-complete">
            {{ t("general-modal-Heading", { ns: TranslationNamespaceEnum.postSubmitText }) }}
          </p>
        </div>

        <div class="doc-content-wrapper container">
          <!-- if single doc or multi doc -->
          <slot name="multi-doc"></slot>
          <slot name="single-doc"></slot>
        </div>
      </div>

      <div class="prospect-form">
        <div id="eclipse-load">
          <!-- Eclipse Form Loads Here -->
          <slot name="form"></slot>
        </div>
        <slot name="resource"></slot>
      </div>
    </div>
  </div>
</template>
