<!-- If there are multiple documents in the modal -->
<script lang="ts" setup>
  import type { DocumentResource } from  "../../../../widgetDataTs.js";
  import { reactive } from 'vue';
  import imageWithFallback from '../../../../shared-components/image-with-fallback.vue';

  const props = defineProps<{ 
    documents: DocumentResource[]
  }>();

  const showInfo: {[key: string]: boolean} = reactive({});

  props.documents.forEach( doc => {
    showInfo['doc'+ doc.id] = false;
  });

  var genericThumbUrl = "https://static.mastercontrol.com/assets/persist/images/document-thumbnail-generic.png";

  function toggleInfo(id: string) {
    showInfo[id] = !showInfo[id];
    if(showInfo[id]) {
      $('#'+ id).find('.info-expand').slideDown();
    } else {
      $('#'+ id).find('.info-expand').slideUp();
    }
  }
</script>

<template>
  <div class="requested-resources r-scroll">
    <div class="r-accordion">
      <ul class="resource-list">
        <template v-for="doc in documents" :key="doc.id">
          <li :id="'doc'+ doc.id" class="resource-row align-items-start" :class="{active: showInfo['doc'+ doc.id]}">
            <div class="tease-doc-links document" :data-resource-type="doc.docType" @click="$event => toggleInfo('doc'+ doc.id)">
              <imageWithFallback class="doc-thumb mr-4" 
                :alt="doc.thumbnailAltText"
                :src="doc.thumbnail"
                :fallback-src="genericThumbUrl"
              />
              <p class="resource-heading">
                {{ doc.title }}
              </p>
              <i v-if="showInfo['doc'+ doc.id]" class="fal fa-minus info-click"></i>
              <i v-else class="fal fa-plus info-click"></i>
            </div>
            <div class="info-expand" style="display: none;">
              <p>{{ doc.description }}</p>
            </div>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>