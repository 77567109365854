import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "thumbnail-wrapper" }
const _hoisted_2 = { class: "d-flex flex-column download-now" }
const _hoisted_3 = { class: "content-label" }
const _hoisted_4 = { class: "doc-title" }
const _hoisted_5 = { class: "before-complete" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode($setup["imageWithFallback"], {
        class: "main-thumbnail",
        alt: $props.document.thumbnailAltText,
        src: $props.document.thumbnail,
        "fallback-src": $setup.genericThumbUrl
      }, null, 8 /* PROPS */, ["alt", "src", "fallback-src"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString($props.document.docType), 1 /* TEXT */),
      _createElementVNode("p", _hoisted_4, _toDisplayString($props.document.title), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", null, _toDisplayString($props.document.description), 1 /* TEXT */)
      ])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}