<!-- If there is a single document in the modal -->
<script lang="ts" setup>
  import type { DocumentResource } from '../../../../widgetDataTs';
  import { useFormAssetDownload } from '../../../composables/use-form-asset-download.js';
  import imageWithFallback from '../../../../shared-components/image-with-fallback.vue';
  
  const { downloadResourceTracking } = useFormAssetDownload();

  defineProps<{ 
    documents: DocumentResource[];
  }>();

  const genericThumbUrl = "https://static.mastercontrol.com/assets/persist/images/document-thumbnail-generic.png";
</script>

<template>
  <div class="requested-resources r-scroll">
    <div class="r-accordion">
      <ul class="resource-list">
        <template v-for="doc in documents" :key="doc.id">
          <li class="resource-row align-items-start">
            <a aria-label="download document" 
              class="shown-doc-links document item-thumb" 
              :href="`/customapi/DocumentDownload/GetDocument/${doc.legacyId}?lang=${doc.language}`"
              @click="downloadResourceTracking({ asset: doc, ctaText: 'GET FREE RESOURCES', ctaType: 'button' })"
            >
              <imageWithFallback class="doc-thumb mr-4" 
                :alt="doc.thumbnailAltText"
                :src="doc.thumbnail"
                :fallback-src="genericThumbUrl"
              />
              <p class="resource-heading">{{ doc.title }}</p>
              <i class="fal fa-arrow-to-bottom info-click"></i>
            </a>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>