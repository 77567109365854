import { createCommentVNode as _createCommentVNode, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "eclipse-modal-content",
  class: "eclipseBodyContent"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" In Modal "),
    ($props.isInModal)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createCommentVNode(" Document Body Slot "),
          _createVNode($setup["modalBody"], { documents: $setup.documents }, _createSlots({ _: 2 /* DYNAMIC */ }, [
            ($setup.documents.length > 1)
              ? {
                  name: "multi-doc",
                  fn: _withCtx(() => [
                    ($setup.showUnlockedView)
                      ? (_openBlock(), _createBlock($setup["multiComplete"], {
                          key: 0,
                          documents: $setup.documents
                        }, null, 8 /* PROPS */, ["documents"]))
                      : (_openBlock(), _createBlock($setup["multiDocument"], {
                          key: 1,
                          documents: $setup.documents
                        }, null, 8 /* PROPS */, ["documents"]))
                  ]),
                  key: "0"
                }
              : undefined,
            ($setup.documents.length === 1)
              ? {
                  name: "single-doc",
                  fn: _withCtx(() => [
                    ($setup.showUnlockedView)
                      ? (_openBlock(), _createBlock($setup["singleComplete"], {
                          key: 0,
                          document: $setup.documents[0]
                        }, null, 8 /* PROPS */, ["document"]))
                      : (_openBlock(), _createBlock($setup["singleDocument"], {
                          key: 1,
                          document: $setup.documents[0]
                        }, null, 8 /* PROPS */, ["document"]))
                  ]),
                  key: "1"
                }
              : undefined,
            ($setup.showUnlockedView)
              ? {
                  name: "resource",
                  fn: _withCtx(() => [
                    _createVNode($setup["resources"], {
                      documents: $setup.documents,
                      ungated: $setup.ungatedView
                    }, null, 8 /* PROPS */, ["documents", "ungated"])
                  ]),
                  key: "2"
                }
              : {
                  name: "form",
                  fn: _withCtx(() => [
                    _createVNode($setup["resourceForm"], {
                      "form-settings-key": $props.formSettingsKey,
                      "widget-data": $props.widgetData,
                      "custom-data": $props.customData
                    }, null, 8 /* PROPS */, ["form-settings-key", "widget-data", "custom-data"])
                  ]),
                  key: "3"
                }
          ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["documents"])
        ]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createCommentVNode(" Not in Modal "),
          (!$setup.showUnlockedView)
            ? (_openBlock(), _createBlock($setup["resourceForm"], {
                key: 0,
                "form-settings-key": $props.formSettingsKey,
                "widget-data": $props.widgetData,
                documents: $setup.documents,
                "custom-data": $props.customData
              }, null, 8 /* PROPS */, ["form-settings-key", "widget-data", "documents", "custom-data"]))
            : ($setup.showUnlockedView)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createVNode($setup["singleComplete"], {
                    document: $setup.documents[0]
                  }, null, 8 /* PROPS */, ["document"]),
                  _createVNode($setup["resources"], { ungated: $setup.ungatedView }, null, 8 /* PROPS */, ["ungated"])
                ], 64 /* STABLE_FRAGMENT */))
              : _createCommentVNode("v-if", true)
        ], 64 /* STABLE_FRAGMENT */))
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}