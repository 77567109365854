<script lang="ts">
  // Turn off inheritance through attributes
  export default {
    inheritAttrs: false
  };
</script>

<!-- Requested Resources -->
<script lang="ts" setup>
  import type { DocumentResource } from '../../../widgetDataTs.js';
  import { type Nil } from '@mcwd/typescript-type-guards';
  import { ref } from 'vue';
  import loaderDark from './loader-icon-dark.vue';
  import { useTranslation } from 'i18next-vue';
  import { TranslationNamespaceEnum } from "../../locale/settings/translation-namespaces.js";
  import { useFormServices } from "../../composables/use-form-services.js";
  import { useSiteLanguage } from "../../composables/use-site-language.js";
  import { useFormLogger } from '../../composables/use-form-logger.js';

  const { logger } = useFormLogger();

  const { siteCountryCode} = useSiteLanguage({ callerLabel: "recommended-resources" });

  const props = defineProps<{ 
    documentIds?: string[];
    ungated:boolean;
  }>();
  const documentIds = ref<string[]> (props.documentIds ?? ['948', '742', '884']);
  const documents = ref<DocumentResource[] | Nil>(null);
  const resourcesLoaded = ref(false);

  window.AjaxRepo.ResourceWidgetData.GetDocumentData(documentIds.value.join(","), siteCountryCode).then( docs => {
    documents.value = docs;
    resourcesLoaded.value = true;
  // eslint-disable-next-line @typescript-eslint/unbound-method
  }).catch(logger.error);

  const { t } = useTranslation();
  const { FormState } = useFormServices({ callerLabel: "recommended-resources" });
</script>

<template>
  <template v-if="FormState === 'Loading' || FormState === 'Setup'">
    <div class="form-loading">
      <loaderDark />
      <p> Loading Content</p>
    </div>
  </template>
  <div v-else class="js-recommended-resources eclipse-rr">
    <div class="rc-after">
      <p class="js-arrow-target js-p-mastercontrol-exclusive">
        <strong>{{ t("recommended-resources-Heading", {ns: TranslationNamespaceEnum.postSubmitText}) }}</strong> 
        <template v-if="!ungated">
          {{ t("recommended-resources-Body", {ns: TranslationNamespaceEnum.postSubmitText}) }} 
        </template>
      </p>
    </div>
  </div>
</template>